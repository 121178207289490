import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Justice Partners
			</title>
			<meta name={"description"} content={"Експертні юридичні рішення з індивідуальним підходом"} />
			<meta property={"og:title"} content={"Justice Partners"} />
			<meta property={"og:description"} content={"Експертні юридичні рішення з індивідуальним підходом"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" onloadShow={false} />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
						Зв`яжіться з нами для більш детальної інформації
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
						Ми в Justice Partners Law Firm розуміємо важливість спеціалізованих юридичних послуг, адаптованих до індивідуальних потреб кожного клієнта. Наша фірма здатна вирішувати широкий спектр юридичних проблем у різних секторах.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-2.jpg?v=2024-05-30T12:18:46.484Z"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
					srcSet="https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-2.jpg?v=2024-05-30T12%3A18%3A46.484Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-2.jpg?v=2024-05-30T12%3A18%3A46.484Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-2.jpg?v=2024-05-30T12%3A18%3A46.484Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-2.jpg?v=2024-05-30T12%3A18%3A46.484Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-2.jpg?v=2024-05-30T12%3A18%3A46.484Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-2.jpg?v=2024-05-30T12%3A18%3A46.484Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-2.jpg?v=2024-05-30T12%3A18%3A46.484Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 0px -160px"
				sm-flex-direction="column"
				sm-margin="0px 0px 0px 0"
			>
				<Image
					src="https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12:18:46.478Z"
					display="block"
					width="100%"
					border-radius="25px"
					margin="80px 0px 0px 0px"
					object-fit="cover"
					sm-margin="0 0px 0px 0px"
					srcSet="https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12%3A18%3A46.478Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12%3A18%3A46.478Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12%3A18%3A46.478Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12%3A18%3A46.478Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12%3A18%3A46.478Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12%3A18%3A46.478Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-1.jpg?v=2024-05-30T12%3A18%3A46.478Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-2.jpg?v=2024-05-30T12:18:46.482Z"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
					srcSet="https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-2.jpg?v=2024-05-30T12%3A18%3A46.482Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-2.jpg?v=2024-05-30T12%3A18%3A46.482Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-2.jpg?v=2024-05-30T12%3A18%3A46.482Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-2.jpg?v=2024-05-30T12%3A18%3A46.482Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-2.jpg?v=2024-05-30T12%3A18%3A46.482Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-2.jpg?v=2024-05-30T12%3A18%3A46.482Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/1-2.jpg?v=2024-05-30T12%3A18%3A46.482Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-3%20%281%29.jpg?v=2024-05-30T12:18:46.472Z"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
					margin="0px 0px 80px 0px"
					sm-margin="0px 0px 0 0px"
					srcSet="https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-3%20%281%29.jpg?v=2024-05-30T12%3A18%3A46.472Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-3%20%281%29.jpg?v=2024-05-30T12%3A18%3A46.472Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-3%20%281%29.jpg?v=2024-05-30T12%3A18%3A46.472Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-3%20%281%29.jpg?v=2024-05-30T12%3A18%3A46.472Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-3%20%281%29.jpg?v=2024-05-30T12%3A18%3A46.472Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-3%20%281%29.jpg?v=2024-05-30T12%3A18%3A46.472Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665839f6fdcae00021e2b3b5/images/2-3%20%281%29.jpg?v=2024-05-30T12%3A18%3A46.472Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text1" />
		</Components.Contacts>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});